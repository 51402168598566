html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px!important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-top, .nav-top1 {
  font-family: 'Nunito', sans-serif;
  font-weight:900;
}
.slide-imgTitle1{
  font-size: 44px;
  position: absolute;
  top: 33%;
  left: 17%;
  color: #fff;
  width: 423px;
  text-align: left;
}
.slide-imgTitle{
  font-size: 44px;
  position: absolute;
  top: 37%;
  right: 13%;
  color: #fff;
}
.green{
  color : #c2fe6b!important;
}
.green-bg{
  background-color : #c2fe6b!important;
}
.yellow{
  color : #ffdb3a!important;
}
.nav1-link{
  text-decoration: none;
}
.nav-top{
  position: fixed;
  background-color: #003220;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 2000;
  left: 0;
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
}
.fixed-top{
  position : relative!important;
}
.navbar{
  flex-wrap: inherit !important;
}
.nav-menu{
  display: contents!important;
}
.top-navbar{
  background-color: #003220;
  width: 66%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
}
.default-div{
  width: 66%;
  margin: auto;
}
/* Privacy - policy section */
.information-contain {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;

}
.privacy-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  padding-top: 100px;
  margin-top : 0px !important;
}

.row{
  margin-right: 38px!important;
  margin-left: 38px!important;
}

.privacy-slide-image {
  min-width: 100%;
  box-sizing: border-box;
}

.testimonial_seecccc{
  width : 70%;
}
.container-privacy {
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
  background-color: #dbffd9;
  border-radius: 10px;
}

.content-privacy {
  margin-bottom: 20px;
}

.heading-privacy {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}

.para-privacy, .privacy-list {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  text-align: justify;
  font-family: 'Nunito', sans-serif;

}

.privacy-list {
  padding-left: 20px;
}

.privacy-list li {
  margin-bottom: 10px;
}

a {
  color: #c2fe6b;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.flip-box {
  background-color: transparent;
  width: 300px;
  height: 200px;
  /*border: 1px solid #f1f1f1;*/
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  /*background-color: #bbb;*/
  color: black;
}

.flip-box-back {
  background-color: #ff914d;
  border-radius : 10px!important;
  color: white;
  padding : 20px!important;
  transform: rotateY(180deg);
}
@media (max-width: 1430px) and (min-width: 800px) {
  .navbar {
    width: 95%!important;


  }
  .achive-box {
    flex-direction: row!important;
    max-width: none!important;
  }
  .achive-box .h3-heading {
    text-align: left!important;
     }
  .achive-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .trending-container {
    width: 95%!important;
  }
  .top-navbar {
    width: 95%!important;
  }
  .contact-testimonial-section {
    width: 95%!important;
  }
  .default-div {
    width: 95%!important;
  }
}
.blog-post {
  text-align: justify;
}
.slide-blog {
  width: 100%;
  height: 700px
}
@media (max-width: 800px) {
  .submit-button {
    width: auto!important;
  }
  .faq-slide{
    height : 200px;
  }
  .textarea-effect-16 {
    width: 100%!important;
  }
  .slide-height {
    height: 414px!important;
  }
.appointment{
  font-size: 18px!important;
}
.contact-info1{
  font-size: 16px!important;
}

.appointment-form{
  /*padding: 24px!important;*/
}
.testimonial-img{
  width: 100% !important
}
.testi{
  margin-top: 20px!important;
}
.und-heading{
  font-size: 24px!important;
}
.und-content{
  padding-left: 18px;
  padding-right: 18px;
}
.achive-header{
  font-size: 38px;
  margin-top: -32px;
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}
.h3-heading{
  font-size: 35px!important;
}
.weight-loss-icon{
  width: 90px!important;
}
.youtube-head{
  font-size: 29px;
}
  .textarea-effect-16:focus ~ .focus-border,
  .has-content.effect-16 ~ .focus-border {
    width: 100%!important;
    transition: 0.4s;
  }
  .form-col-3 {
    width: 100%!important;
  } /* necessary to give position: relative to parent. */
  .blog-post {
    text-align: inherit!important;
  }
  .slide-blog {
    height: 100% !important
  }
  .und-item{
    margin-bottom : 10px!important;
  }
  .und-column{
    padding : 0px!important;
  }
  .trending-container {
    flex-flow: column!important;
    width: 100% !important;
  }
  .container-contact {
    flex-direction: column!important;
  }
  .privacy-slide-image{
    height : 200px;
  }
  .contactInfo {
    padding: 0!important;
    width: auto!important;
    max-width: none!important;
  }
  #contact {
    padding: 0px!important;
    padding-left: 20px!important;
    padding-right: 20px!important;
    padding-bottom: 20px!important;
  }
  .contact-items {
    width: auto!important;
    max-width: none!important;
  }
  .hamburger-menu {
    position: fixed;
    left:0;
    top:42px;
    height:250px;
    z-index: 999999;
    background-color:#fff;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .nav-menu {
    flex-flow: column;
  }
  .top-navbar {
    width: auto;
    padding-left: 30px
  }
  .nav-branding {
    gap: 0;
  }
  .navbar {
    width: 100%!important;
    padding-left: 25px!important;
    justify-content: normal!important;
  }
  .nav-item {
    margin-right: 2px
  }
  .contact-testimonial-section {
    width: auto!important;
    padding: 20px!important;
  }
  .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
}
.contact-info11{
  padding-left : 27px!important;
  padding-right : 27px!important;
}

  .multi-select-react {
    width: 100%!important;
  }
  .slide-about {
    flex-direction: column!important;
  }
  .und-content {
    flex-direction: column;
    align-content: center;
  }
  .und-column {
    max-width: none!important;
    padding: 10px
  }
  .default-div {
    width: 100%;
  }
  .achive-sec {
    height: auto;
    padding-bottom: 50px!important;;
  }
  .contact-footer2 {
    flex-direction: column!important;
    text-align: center;
  }
  .nav-link-bookapp {
    font-size: 10px!important;
  }
  .heading {
    font-size: 16px!important;
  }
}
.multi-select-react {
  width: 100%;
  background-color: #003220;
  height: 38px;
  line-height: 6px;
  border: none;
  color: white;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #333 !important;
  margin-left: -10px;
}
.contact-info{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin: 0;
}
.contact-info li{
  margin-right: 10px;
}

.contact-info li.middle{
  flex: auto;
}
.contact-info li.right {
}

.contact-info .fa-phone,
.contact-info .fa-envelope,
.contact-info .fa-clock {
  background-color: #dbffd9;
  color: black;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.nav1-link {
  transition: 0.5s ease;
}

.nav1-link:hover {
  color: #00b300;

}
.contact-info li{
  display: flex;
  align-items: center;

}
.contact-info a{
  color: #dbffd9;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}


.hamburger {
  display: block;
  justify-content: flex-end;
  margin-left: auto;
  padding-right: 20px;
}

.hamburger.active .bar:nth-child(1),
.hamburger.active .bar:nth-child(2),
.hamburger.active .bar:nth-child(3) {
  transform: rotate(0deg);
}

.bar {
  display: block;
  width: 25px;
  height: 2px;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
  background-color: white;
}
/* Main Navbar Styles */
.nav-top1{
  background-color: white;
  position: fixed;
  overflow: hidden;
  z-index: 2000;
  width: 100%;
  top: 40px;
  left: 0;
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
}
.navbar {
  background-color: white;
  width: 66%;
  display: flex;
  justify-content: center!important;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.nav-branding{
  display: flex;
  align-items: center;
  gap: 5px;
  color: black;
  text-decoration: none;
  flex: auto;
}
.nav-branding img {
  height: 45px;
  border-radius: 50%;
}
.nav-branding span {
  font-size: 24px;
  font-weight: bold;
}

.nav-menu {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 40px;
  list-style: none;
}

.nav-link {
  color: black;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  padding: 8px 0;
  cursor: pointer;
  text-decoration: none;

}

.nav-link:hover {
  color: #00b300;
  transform: scale(1.3);
}
.nav-link-bookapp{
  background-color: #003220;
  border-radius: 30px;
  padding: 10px 10px;
  font-size: 13px;
  color: white;
  transition: all 0.5s ease;
  cursor: pointer;
}
.nav-link-bookapp:hover{
  background-color: #00b300;
  color: white;
  transform: scale(1.3);
}
.section3{
  background-color: #003220
}

.contact-testimonial-section{
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 20px;
  background-color: #003220;
  padding-top: 40px;
  width: 66%;
  margin: auto;
}
.schedule-appointment-container{
  color: #dbffd9;
  margin: 0;
  margin-top: -33px;
  padding-left: 0px;
  z-index:0
}
.schedule-appointment {
  text-align: center;
  padding: 20px !important;
  padding-top: 45px !important;
}
.schedule-header{
  position: relative;
  display: inline-block;
  padding: 30px!important;
}
.contact-info1{
  line-height : normal!important;
}
.circle-bg {
  background-color: #dbffd9;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.circle-bg h2 {
  margin: 20px;
  font-size: 14px;
  color: black;
}
.appointment{
  /*font-family: "Roboto", sans-serif;*/
  background-color: #dbffd9;
  color: #000;
  font-size: 30px;
  margin-top: 2px;
  border-radius: 12px;
  padding: 10px;
  transition: transform 0.3s;
  cursor: pointer;
  font-weight : bolder;
}
.appointment:hover{
  transform: scale(1.1);
}
.contact-info11{
  text-align : center!important;
  padding-top: 20px;
  line-height: 24px;
  color: #ff914d;
  font-size: 20px;
  padding-left: 48px;
  padding-right: 68px;
}
.contact-info1{
  font-size: 25px;
  color: #ff914d;
  padding-left: 48px;
  padding-right: 48px;
}
.form-group{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}
.multi-select {

}
.multi-container{
  position: relative;
  width: 48%;
  max-width: auto;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-bottom: 15px;
}
.select-btn{
  background-color: rgba(255, 255, 255, 0.1);
  color: #dbffd9;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.select-btn .btn-text{
  color: #dbffd9;
}
.list-items{
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  background-color:black;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}
.item-list{
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.item-list:hover{
  background-color: #333;
}
.checkbox-container{
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.checkbox-container .checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid #dbffd9;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}
.item-text {
  color: #dbffd9;
}

.arrow-down {
  color: #dbffd9;
}

.select-btn.open + .list-items {
  display: block;
}
.form-group textarea{
  width: 100%;
  height: 100px;
}
.consultation-mode {
  display: flex;
  justify-content: space-between;
  width: 48%;
  color: #ff914d;
}
.consultation-mode label {
  display: flex;
  align-items: center;
}
.consultation-mode p {
  display: flex;
  align-items: center;
}
.contact-footer{
  display: flex;
  justify-content: center!important;
  align-items: center;
  gap: 20px;
}

.submit-button {
  display: inline-block;
  background-color: #ff914d;
  color: black;
  padding: 5px 10px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.submit-button:hover{
  background-color: #00b300!important;
}
.social-media-list{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.social-media-list img{
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s;

}
.social-media-list img:hover{
  transform: scale(1.2);
}

.social-media-list li {
  margin: 0 10px;
  font-size: 20px;
}
.para-sub{
  background-color: #10ffb2;
  color: #000;
  padding: 10px;
  border-radius: 15px;
  font-size: 15px;
  transition: transform 0.3s;
  cursor: pointer;
}
.para-sub:hover{
  transform: scale(1.05);
}
.para-sub .scrolling-text {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  animation: scroll-text 18s linear infinite;
}

.para-sub .scrolling-text:nth-child(1) {
  left: 100%;
}

.para-sub .scrolling-text:nth-child(2) {
  left: calc(100% + 20px);
}

@keyframes scroll-text {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.contact-form-container {
  flex: 1;
  max-width: 100%;
}

.contact-form-container h2 {
  margin-bottom: 20px;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
}

.contact-form-container label {
  margin-bottom: 5px;
}

.contact-form-container button {
  padding: 12px 20px;
  background-color: #007BFF;
  color: #dbffd9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.contact-form-container button:hover {
  background-color: #00b300;
}

/* Testimonial Slider */
.testi{
  text-align: center;
  color: black;
  font-size: 15px;
  padding: 10px;
  background-color: #a7ee3a;
  border-radius: 20px;
  width : 62%;
  margin-top:20px!important;
  margin-left: auto!important;
  margin-right: auto!important;
  font-weight: bolder;

}
.testimonial-slider {
  flex: 1;
  max-width: 100%;
  position: relative;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #dbffd9!important;
}
.testimonial-name{
  /*text-align: center!important;*/
  padding-top: 10px!important;
  margin-left : 18px
}
.slider2 {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.slides2 {
  display: flex;
  transition: transform 1s ease-in-out;
}

.slide2 {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /*padding: 25px;*/
}

.slide2 img {
  width: 250px;
  height: 250px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.paragraph{
  color: #dbffd9;
  font-family: 'Nunito', sans-serif;
}

.prev-button2, .next-button2 {
  position: absolute;
  top: 67%;
  transform: translateY(-50%);
  background-color: #dbffd9;
  color: black;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.prev-button2:hover, .next-button2:hover {
  background-color: #00b300;
}

.prev-button2 {
  left: 40%;
}

.next-button2 {
  right: 45%;
}


/* <!-- About section profile  --> */
.about-sec{
  background-color: white;
}
.slide-section {
  background-color: white!important;
  color : #000000 !important;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  /*padding: 20px;*/
  overflow: hidden;
}

.slide-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  gap: 30px;
}

.slide-about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;

}

.slide-profile {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;

}

.slide-img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.profile-contant {
  flex: 2;
  margin-left: 20px;
  margin-right: 15px;
  padding: 50px;
}

.profile-heading {
  text-align: left;
  font-size: 1.5em;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;

}

.profile-para {
  font-size: 1em;
  line-height: 1.5;

}

.profile-prevbtn, .profile-nextbtn {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  background-color: #003220;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.profile-prevbtn {
  left: 49%;
}

.profile-nextbtn {
  left: 52%;
}

.profile-prevbtn:hover, .profile-nextbtn:hover {
  background-color: #ff914d;
}
.profile-contant{
  padding: 20px;
}
.profile-heading{
  font-size: 30px;
  margin-bottom: 10px;
}
.profile-details{
  padding-left: 0px;
  align-items: flex-start;
  gap: 20px;
  padding-top: 10px;
  font-family: 'Nunito', sans-serif;

}
.profile-details i {
  font-size: 25px;
  margin-right: 10px;
  text-align: center;
}
.profile-details li{
  margin-bottom: 15px;
  display: flex;
  align-items:center;
  gap: 20px;
  text-align: justify;
  font-family: 'Nunito', sans-serif!important;
}


/* <!-- Lets Understand our program section --> */
.und-sec{
  padding: 20px;
  background-color: #003220!important;
}
.und-container{
  color: white;
  margin: 0 auto;
  text-align : center;
}
.und-heading{
  padding: 30px;
  text-align: center;
  font-size: 40px;
  font-family: 'Satisfy', cursive;
  color : white!important;
  font-weight : bolder;
}
.und-content{
  display: flex;
  flex-wrap: wrap;
  max-width: 880px;
  margin: 0 auto;
  justify-content: space-between;
  gap: 10px;
  padding-top: 20px;
  text-align: left;
}
.und-column{
  flex: 1;
  max-width: 50%;
  box-sizing: border-box;
}
.und-item{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.und-icon{
  background-color: #ff914d;
  border-radius: 70px;
}
.und-icon img{
  width: 60px;
  height: 60px;
}
.und-icon i{
  font-size: 30px;
}
.und-para{
  color: white!important;
  margin-left: 20px;
  flex-grow: 1;
}
.und-head{
  text-transform: uppercase;
}
.und-para h3 {
  font-size: 15px;
  color: white!important;
  margin-bottom: 5px;
  font-weight : bolder;
}
.und-para p {
  font-size: 14px;
  color: white!important;
  line-height: 1.6;
  font-family: 'Nunito', sans-serif;

}
.und-para a {
  color: #f47b2f;
  text-decoration: underline;
  transition: all 0.3s ease;

}
.und-para a:hover {
  color: #f3c16f;
}




/* <!-- Service Section --> */

#service {
  overflow: hidden;
  height: 450px;
}

.service-sec {
  padding: 30px;
  background-color: white;

}

.service-container {
  margin: 0 auto;
  position: relative;
}

.service-header {
  color : white!important;
  text-align: center;
  margin-bottom: 10px;
  font-size: 50px;
  font-weight: bolder;
  font-family: 'Satisfy', cursive;
}
.slide-content {
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
  height: 350px;
}

.service-slide {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  height: 350px;
}

.box {
  width: 100%;
  max-width: 350px;
  border-radius: 25px;
  margin: 0 auto;
  padding-bottom: 15px;
  height: 350px;
  transition: transform 0.3s ease-in-out;
}
.box:hover {
  transform: scale(1.1);
}

.image-content,
.img-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 350px;
}
.image-content {
  position: relative;
  row-gap: 5px;
  height: 450px;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.box-image {
  position: relative;
  padding-top: 30px;
  height: 420px;
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  margin: 0 auto;
}

.box-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.h2-name {
  font-size: 18px;
  font-weight: 500;
  color: white!important;
  margin-top: 5px;
  padding-top: 0;
  z-index: 1;
  font-family: 'Nunito', sans-serif;

}


.btn4, .btn5 {
  background-color: #dbffd9;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  z-index: 10;
}

.btn4 {
  left: -30px;
}

.btn5 {
  right: -30px;
}

.btn4:hover, .btn5:hover {
  background-color: #008152;
}


@media (min-width: 768px) {
  .service-wrapper {
    justify-content: space-between;
  }

  .service-slide {
    flex: 0 0 33.3333%;
  }

  .btn4, .btn5 {
    display: block;
  }
}


/* <!-- About section profile  --> */
.about-sec{
  background-color: white;
}
.slide-section {
  background-color: white;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  /*padding: 20px;*/
  overflow: hidden;
}

.slide-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  gap: 30px;
}

.slide-about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  /*padding: 20px 0;*/
  flex-shrink: 0;
}

.slide-profile {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.profile-contant {
  flex: 2;
  margin-left: 20px;
  margin-right: 15px;
}

.profile-heading {
  font-weight: bolder;
  margin-bottom: 10px;



}

.profile-para {
  font-size: 1em;
  line-height: 1.5;

}
/* understand page */
/*.und-sec{*/
/*  height: 780px;*/
/*}*/
/*.und-content{*/
/*  padding-bottom: 0px;*/
/*}*/
/*.und-heading{*/
/*  font-size: 25px;*/
/*  padding-top: 0px;*/
/*}*/
/*.und-item{*/
/*  width: 350px;*/
/*}*/

/*.und-content{*/
/*  !* gap: 20px; *!*/
/*  padding: 10px;*/
/*  padding-top: 0px;*/
/*}*/
/*.und-item{*/
/*  padding-right: 20px;*/
/*}*/
/*.und-icon{*/
/*  width: 40px;*/
/*  height: 40px;*/
/*}*/
/*.und-icon img{*/
/*  width: 40px;*/
/*  height: 40px;*/
/*}*/
/*.und-head{*/
/*  font-size: 10px;*/
/*}*/
/* service section */
#service{
  padding-top: 10px;
  height: 370px;
}
.service-header{
  font-size: 28px;
  margin: 0px;

}
.img-content{
  padding-left: 0px;
  padding-right: 0px;
}
.btn4, .btn5{
  top: 45%;
}
.btn4 {
  left: -15px;
}
.btn5 {
  right: -15px;
}
.service-container{
  padding-bottom: 0px;
  padding-top: 0px;
}

/* blog - section */
.blog-sec{
  padding-top: 10px;
}
.blog-container{
  padding-top: 0px;
  padding-bottom: 0px;
}
.blog-heading{
  font-size: 28px;
}
.blog-img-content{
  padding-left: 0px;
  padding-right: 0px;
}
.blog-description{
  font-size: 12px;
}
.blog-prevbtn, .blog-nextbtn{
  top: 45%;
  width: 20px;
  height: 20px;
}
.blog-prevbtn{
  left: -19px;
}
.blog-nextbtn{
  right: -19px;
}

/* Blog-1 section */
.blog-img{
  width: 100%;
  height: 100%;
}
.Headings-Blog1{
  margin-left: 12px;

}
.blog1-heading{
  font-size: 25px;
}
.blog1-headingh2{
  font-size: 14px;
}
.container-blog1{
  width: 400px;
}

/* Achievement section */
.achive-sec{
  padding: 25px;
}
.achive-header {
  font-size: 28px;
  text-align: center;
  margin-top: -50px;
  padding-bottom: 25px;
}
.achive-container{
  padding-top: 20px;
}
.pt10 {
  padding-top: 10px
}
.achive-container-mobile {
  display: inline;
  float: left;
}
.weight-loss-icon{
  width: 50px;
}

.achive-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
}


.achive-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  max-width: 150%;
  width: 100%;
  margin: 0 auto;
  height: 150px;
  padding-left: 0px;
  padding-right: 0px;
}

.achive-box {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
  flex-direction: row;
  margin: 0 auto;
  margin-right: 25px;
}
.achive-box .h3-heading{
  transition: color 0.6s ease;
  text-align : center;
  font-size: 30px;
}
.achive-icon {
  font-size: 30px;
  margin: 0;
  width: 50px;
  height: 50px;
}

.achive-headings {
  text-align: left;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.h3-heading {
  font-size: 24px;
  margin: 0;
}

.p-para {
  font-size: 16px;
  margin: 0;
}
.modal-content{
  height: 70%;
}
.container-blog1{
  width: 340px;
  padding-left: -20px;
  display: flex;
  justify-content: left;
  padding-left: 0px;
}
.img-blog1{
  padding-left: 0px;
  height: 300px;
  width: 270px;
}
.para-blog1{
  margin-top: -30px;
  width: 290px;
  padding-left: 0px;
  padding-top: 0px;
}
/* youtube section */

#trending-section{
  height: 950px;
  padding: 20px;
  padding-right: 20px;
}
.trending-container {
  flex-direction: column;
}
.youtube-head{
  padding: 0px;
  font-size: 28px;
  padding-bottom: 10px;
}
.video-card iframe {
  width: 330px;
  height: 205px;
}



/* contact page */
.contact-sec{
  padding-top: 0px;
}
.contact-head{
  font-size: 28px;
}
.contact-headings {
  text-align: left;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
#contact{
  padding:20px;
}
.container-contact{
  display: flex;
  justify-content: left;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.contactForm{
  width: 100%;
}
.contactInfo{
  /*width: 400px !important;*/
  padding: 30px;
  line-height: 0px;
}
.contact-heading{
  font-size: 20px;
}
.para-app{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 390px;
  padding: 30px;
}
.cont-appointement{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* FQA section */
.feq-conatiner{
  padding-top: 20px;
}

/* footer-section 2 */
.faq-section{
  padding-top: 5px;
  padding-bottom: 10px;
}
.faq-heading{
  font-size: 25px;
  padding-bottom: 30px;
  padding: 10px;
}
.footer2-container{
  flex-direction: column;
}
.footer2-privacy{
  margin-right: 0;
  align-items: center;
}
.contact-footer2,
.footer2-privacy,
.col-md-51{
  margin: 10px 0;
}

/* <!-- Service Section --> */

#service {
  overflow: hidden;
  height: 450px;
}

.service-sec {
  padding: 30px;
  background-color: white;

}

.service-container {
  max-width: 1030px;
  margin: 0 auto;
  position: relative;
}

.service-header {
  text-align: center;
  color: white!important;
  margin-bottom: 10px;
  font-size: 50px;
  font-weight: 600;
  font-family: 'Satisfy', cursive;
}
.slide-content {
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
  height: 350px;
}

.service-slide {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  height: 350px;
}

.box {
  width: 100%;
  max-width: 350px;
  border-radius: 25px;
  margin: 0 auto;
  padding-bottom: 15px;
  height: 350px;
  transition: transform 0.3s ease-in-out;
}
.box:hover {
  transform: scale(1.1);
}

.image-content,
.img-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 350px;
}
.image-content {
  position: relative;
  row-gap: 5px;
  height: 450px;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.box-image {
  position: relative;
  padding-top: 30px;
  height: 420px;
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  margin: 0 auto;
}

.box-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.h2-name {
  font-size: 18px;
  font-weight: 500;
  color: white!important;
  margin-top: 5px;
  padding-top: 0;
  z-index: 1;
  font-family: 'Nunito', sans-serif;

}


.btn4, .btn5 {
  background-color: #dbffd9;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  z-index: 10;
}

.btn4 {
  left: -30px;
}

.btn5 {
  right: -30px;
}

.btn4:hover, .btn5:hover {
  background-color: #008152;
}


@media (min-width: 768px) {
  .service-wrapper {
    justify-content: space-between;
  }

  .service-slide {
    flex: 0 0 33.3333%;
  }

  .btn4, .btn5 {
    display: block;
  }
}



/* <!-- Blog section --> */

.blog-sec{
  padding: 20px;
  background-color: white!important;
  color : Black!important;
}
.blog-container{
  max-width: 1030px;
  margin: 0 auto;
  padding: 30px 0;
  position: relative;
}
.blog-heading{
  text-align: center;
  color: black!important;
  margin-bottom: 10px;
  font-size: 50px;
  font-family: 'Satisfy', cursive;
}
.blog-slide-content{
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-wrapper{
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
  height: 350px;
}
.blog-slide{
  flex: 0 0 auto;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;

}
.blog-box {
  width: 100%;
  max-width: 350px;
  border-radius: 25px;
  margin: 0 auto;
  padding-bottom: 15px;
  transition: transform 0.3s ease-in-out;
}
.blog-image-content,
.blog-img-content{
  display: flex;
  flex-direction: column;
  text-align: center;
}
.blog-image-content{
  position: relative;
  row-gap: 5px;
}
.blog-overlay{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.blog-box-image {
  position: relative;
  padding-top: 30px;

}

.blog-img-content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blog-box-image img{
  width: 100%;
  height: auto;
}
.blog-description{
  font-size: 13px;
  text-transform: uppercase;
  color: black!important;
  padding-top: 15px;
  text-align: center!important;
  font-family: 'Nunito', sans-serif;

}
.blog-button{
  border: none;
  font-size: 15px;
  color: black;
  background-color: #c2fe6b;
  border-radius: 20px;
  margin: 0 auto;
  /*margin-top: 20px;*/
  cursor: pointer;
  transition: all 0.3s ease;
  width: 35%;
  font-family: "Lato", sans-serif;
  height:39px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.blog-button:hover{
  background-color: #00b300;
  transform: scale(1.05);
}
.blog-prevbtn,
.blog-nextbtn{
  background-color: #dbffd9;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  color: black;
  transition: tra;
}
.blog-prevbtn{
  left: -30px;
}
.blog-nextbtn{
  right: -30px;
}
.blog-prevbtn:hover, .blog-nextbtn:hover{
  background-color:  #008152;
}

@media(min-width: 768px){
  .blog-slide{
    flex: 0 0 33.3333%;
  }
}


@media(max-width: 767px){
  .blog-slide{
    flex: 0 0 100%;
  }
}

/* Blog-1 section */
.blog-img{
  width: 100%;
  height: 650px;
}
.blog1-content{
  text-align: center;
}
.Headings-Blog1{
  padding-top: 50px;
  text-align: left;
  margin-left: 10% ;
}
.blog1-heading{
  font-size: 40px;
}
.blog1-headingh2{
  font-size: 20px;
}

.container-blog1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 40%;
  margin: 0 auto;
  box-sizing: border-box;
}

.img-blog1,
.para-blog1 {
  width: 769px;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-y: hidden;
}

.blog1-image {
  width: 100%;
  height: auto;
}

.para-blog1 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.blog1-para {
  margin: 0;
  text-align: justify;
}

/* <!-- Our Achieveents --> */
.achive-sec{
  padding: 20px;
  background-color: white;
}
.achive-container{
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.achive-header{
  text-align: center;
  margin-bottom: 30px;
  font-size: 50px;
  font-weight: bolder;
  padding-top: 45px;
  font-family: 'Satisfy', cursive;
}
.achive-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.achive-wrapper{
  display: flex;
  gap: 0px;
  padding-bottom: 50px;
  flex-direction: row-reverse;
}
.achive-box{
  border-radius: 10px;
  text-align: center;
  flex: 1 1 250px;
  max-width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.achive-icon{
  font-size: 55px;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 115px;
  border-radius: 50%;
}
.weight-loss-icon {
  width: 120px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

}
.weight-loss-icon:hover{
  transform: scale(1.2);
}


.h3-heading{
  font-size: 45px;
  color: #003220;
}
.p-para{
  font-size: 20px;
  margin: 0;
  color: #030911;
  font-family: 'Nunito', sans-serif;

}


/* Youtube section */
#trending-section {
  padding: 20px;
  background-color: #003220;
  height: auto;
}
.youtube-container{
  /*max-width: 900px;*/
  /*margin: 0 auto;*/

}
.youtube-head{
  color: #dbffd9;
  font-size: 50px;
  padding: 20px;
  text-align: center;
  font-family: 'Satisfy', cursive;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bolder;
}
.trending-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 20px;
  width: 66%;
  margin: auto;
}
.view-more-video {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 66%;
  margin: auto;
}
.video-card{
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.3s;
  justify-content: center;
  width: 100%;
  max-width: 380px!important;
  margin: 0 auto;
}
.video-card:hover {
  transform: scale(1.05);
}
.video-card img{
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.thumbnail {
  width: 100%;
  border-radius: 8px;
}

.video-title {
  color: white;
  font-size: 18px;
  font-family: 'Nunito', sans-serif;

}
.youtube-button{
  background-color: #ff914d;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition:  transform 0.3s ease-in-out;
  position: relative;
  float: right;
  font-weight : 600;
  /*font-family: 'Nunito', sans-serif;*/

  font-size: 15px;
  font-weight: bold;
}
.youtube-button:hover{
  transform: scale(1.2);
}

#contact {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 60px;
  max-width: 2030px;
  width: 100%;
  margin: 0 auto;
}

.contact-items {
  max-width: 66%;
  width: 66%;
}

.contact-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1030px;
  margin: 0 auto;
  background-color: white;
}

.contact-head {
  font-size: 50px;
  font-family: 'Satisfy', cursive;
}

.content-contact {
  text-align: center;
  padding: 10px;
}

#contact .content-contact .cont-appointement {
  font-size: 20px;
}

.container-contact {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.contactInfo {
  font-size: small;
}

.container-contact .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.container-contact .contactInfo .contant-box {
  position: relative;
  padding: 20px 0;
  display: flex;
  gap: 20px;
}

.contant-box {
  font-size: 20px;
  gap: 30px;
}
.text a{
  color: #003220;
}
.icon-contact i {
  font-size: 24px;
  color: #000; /* Default color */
  margin-right: 15px;
  transition: color 0.2s ease;
}

.text a {
  text-decoration: none;
  color: #000; /* Default text color */
  transition: color 0.2s ease;
}

/* Change color on click (active state) */
.icon-contact i:hover,
.text a:hover {
  color: #ff914d; /* Change to red when clicked */
  transform: scale(1.2);
}

.container-contact .contactInfo .contant-box .icon-contac {
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 22px;
}
.contact-link{
  cursor: pointer;
  color: #000;
}
.pointer{
  cursor: pointer;
}
.contactForm {
  background-color: #dbffd9;
  border-radius: 15px;
  padding: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.contact-heading{
  font-size: 30px;
  padding-bottom: 20px;
  color: #003220;

}
.contact-para{
  text-align: justify;
}
.cont-paraa{
  font-size: 20px;
  padding: 10px;
  color: #ff914d;
  text-decoration: underline;
  transition: transform 0.3s;
}
.cont-paraa:hover{
  color: #00b300;
}

.whatsapp-btn {
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 1000;
  display: flex;

}
.whatsapp-btn img{
  transition: transform 0.3s;
  width: 70px;
  height: 70px;
}
.whatsapp-btn img:hover{
  transform: scale(1.2);
}

a:-webkit-any-link {
  cursor: pointer;
}
.fa-location-dot{
  color: #003220;
}
.phone{
  color: #003220;
}
.email1{
  color: #003220;
}
.icon-contact{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
/* <!-- Frequently asked section --> */
.faq-section{
  padding: 20px;
  background-color: #003220;
}
.Collapsible__trigger{
  cursor: pointer;
}
.faq-div{
  max-width: 1000px;
  margin: 0 auto;
}
.faq-heading{
  font-size: 50px;
  padding-bottom: 40px;
  text-align: center;
  color: #dbffd9;
  font-family: 'Satisfy', cursive;
}
.faq-item{
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;

}
.faq-question{
  width: 100%;
  background-color: #dbffd9;
  cursor: pointer;
  padding: 20px;
  font-size: 16px;
  border: none;
  text-align: left;
  outline: none;
  transition: transform 1.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-question:hover {
  background-color: #fff;
}
.faq-anw{
  display: none;
  padding: 15px;
  background-color: #dbffd9;
  border-left: 3px solid #2ff4cc;
}
.faq-answer p {
  margin: 0;
  font-size: 14px;
  color: #555;
}
.faq-question i {
  background-color: #dbffd9;
  transition: transform 0.3s ;
}
.faq-question.active i {
  transform: rotate(180deg);
}

.li-items{
  display: flex;
  justify-content: left;
  align-items: center;
}
.list__item {
  display: flex;
  align-items: center;
}
.list-icon{
  margin-right: 10px;
  font-size: 0.8em;
  text-align: center;
}

.fa-solid.fa-circle{
  font-size: 0.2em;

}

/* Footer section */
#footer-sect1{
  background-color: #003220;
  text-align: center;
  color: #fff;
  padding: 12px;
}
.container1{
  max-width: 1200px;
  margin: 0 auto;
  font-size: 9px;
}
.container {
  max-width: 1030px;
  margin: 0 auto;
}
.footer-heading1{
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 600;
}



/* <!-- Footer section 2 --> */

#footer2-sec{
  background-color: #fff;
  padding: 10px;
  font-family: 'Nunito', sans-serif;
}
.footer2-container{
  display: flex;
  max-width: 1030px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 15px;
}
.footer2-container a{
  text-decoration: none;
  color: #000;
}
.footer2-container a:hover{
  color: #003220;
}

.footer2-privacy{
  margin-right: 570px;
}

/* <!-- Footer section 3 --> */
.footer3{
  background-color: #003220;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 12px;
}
.footer3 p{
  margin-bottom: 0px;
  text-align : center!important;
}
/* Testimonial Section Styles */
#testimonial-section {
  overflow: hidden;
  padding: 30px;
  background-color: #f9f9f9; /* Optional for better visibility */
}

.testimonial-container {
  max-width: 1030px;
  margin: 0 auto;
  position: relative;
}

.testimonial-header {
  padding-top : 20px;
  text-align: center;
  color: black;
  margin-bottom: 10px;
  font-size: 50px;
  font-weight: 600;
  font-family: 'Satisfy', cursive;
}

.testimonial-slide-content {
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
  width: 100%;
}

.testimonial-slide {
  flex: 0 0 33.3333%;
  padding: 0 10px;
  box-sizing: border-box;
}

.testimonial-box {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding: 15px;
  border-radius : 12px;
}
.testimonial-img-button {

  margin: 0;
  position: absolute;
  top: 45%;
  left: 45%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
}
.testimonial-box:hover .testimonial-img-button{
  display: flex;
}
.box-image:hover .testimonial-img-button{
  display: flex;
}
.testimonial-box:hover .testimonial-btn:hover, .testimonial-btn:focus {
  color: #fff;
  outline: 0;
}
.testimonial-box:hover .fourth:hover {
  background-position: 0;
}
.testimonial-img-button:hover .testimonial-img-button{
  display: flex;
}
.testimonial-img {
  width: 100%;
  height: auto;
  border-radius : 10px
}
@media (min-width: 800px){
  .testimonial-img:hover {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
}
@media (min-width: 800px){
  .box-img:hover {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
}


.testimonial-btn {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid #00b300!important;;
  border-radius: 1.6em;
  color: #e74c3c;
  cursor: pointer;
  align-self: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.testimonial-btn:hover, .testimonial-btn:focus {
  color: #fff;
  outline: 0;
}

.fourth {
  border-color: #f1c40f;
  color: #fff;
  background-image: linear-gradient(30deg, #00b300 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out;
}
.fourth:hover {
  background-position: 0;
}


.testimonial-name {
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
}

.testimonial-button-prev,
.testimonial-button-next {
  background-color: #003220;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  font-size: 20px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.testimonial-button-prev {
  left: -12px;
}

.testimonial-button-next {
  right: -12px;
}

.testimonial-button-prev:hover,
.testimonial-button-next:hover {
  background-color: #ff914d;
  transform: translateY(-50%) scale(1.1);
}

@media (max-width: 768px) {
  .testimonial-slide {
    flex: 0 0 100%;
  }

  .slide-imgTitle1 {
    font-size: 12px!important;
  }
  .slide-imgTitle {
    font-size: 12px!important;
  }
}


.Collapsible {
  background-color: white;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; }
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px; }
.Collapsible__contentInner p:last-child {
  margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #dbffd9;
  color: #000; }
.Collapsible__trigger:after {
  content: '▼';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 0ms; }
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg); }
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 0ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; }


/*Form Starts Here*/

input[type="text"] {
  font: 15px/24px "Muli", sans-serif;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
select {
  font: 15px/24px "Muli", sans-serif;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

:focus {
  outline: none;
}

.form-col-3 {
  float: left;
  width: 40%;
  margin: 20px 3%;
  position: relative;
} /* necessary to give position: relative to parent. */
.form-col-1 {
  float: left;
  width: 100%;
  margin: 20px 3%;
  position: relative;
} /* necessary to give position: relative to parent. */
input[type="text"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
select {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.effect-16 {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  color: #fff;
}
.textarea-effect-16 {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  color: #fff;
  width: 92%
}
.select-effect-16 {
  border: 0;
  padding: 4px 0;
  background-color: transparent;
  color: #fff;
}
.focus-border {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 0;
  height: 1px;
  transition: 0.4s;
  width: 100%;
  background-color: #ccc;
}
.effect-16 ~ .focus-border {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-16:focus ~ .focus-border,
.has-content.effect-16 ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}
.effect-16 ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-16:focus ~ label,
.has-content.effect-16 ~ label {
  top: -16px;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}

.textarea-effect-16 ~ .focus-border {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
}
.textarea-effect-16:focus ~ .focus-border,
.has-content.effect-16 ~ .focus-border {
  width: 92%;
  transition: 0.4s;
}
.textarea-effect-16 ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 2px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.textarea-effect-16:focus ~ label,
.has-content.effect-16 ~ label {
  top: -16px;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}




.wrapper-contact{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 15px;
  /*margin: 50px;*/
  padding: 0px 20px;
  /*background-color: rgb(241, 245, 248);*/
}
.pricing-table {
  -webkit-box-shadow: 1px 2px 20px 11px rgba(126,151,161,1);
  -moz-box-shadow: 1px 2px 20px 11px rgba(126,151,161,1);
  box-shadow: 1px 2px 20px 11px rgba(126,151,161,1);
  text-align: center;
  padding: 30px 0px;
  border-radius: 5px;
  position: relative;
}

.pricing-table .head {
  padding-bottom: 50px;
  transition: all 0.5s ease;
}

.pricing-table .head .title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}
. pricing-amount{
  line-height : 90!important;
}

.pricing-table .content .price {
  background: linear-gradient(to right, #A7C4A0 10%, #7B8CDE 90%);
  width: 90px;
  height: 90px;
  margin: auto;
  line-height: 90px;
  border-radius: 50%;
  margin-top: -50px;
  transition: all 0.5s ease;
  box-shadow: 0px 0px 12px #2b97c9;
}

.pricing-table:hover .content .price {
  transform: scale(1.2);
}


.pricing-table .content .price h1{
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height : 90px
}
.pricing-table .content ul {
  list-style-type: none;
  margin-bottom: 20px;
  padding-top: 13px;
  text-align : left;
}

.pricing-table .content ul li {
  font-size: 15px;
}

.pricing-table .content .buy-now {
  background: linear-gradient(to right, #A7C4A0 10%, #7B8CDE 90%);
  border-radius: 50px;
  font-weight: 500;
  position: relative;
  display: inline-block;
}

.pricing-table .btn.round {
  color: #fff;
  padding: 14px 40px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3 linear;
  transition: all 0.3 linear;
  border: none;
  font-size: 14px;
  text-decoration: none;
  text-transform: capitalize;
  position: relative;
  margin: 2px;
  z-index: 9999;
}

.pricing-table .btn.round {
  z-index: 50;
  color: #333;
}

.pricing-table:hover .btn.round {
  color:#fff !important;
}
.spam-popular{
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: #1e86cc;
  color: #fff;
  padding: 7px 40px;
  font-size: 20px;
  border-radius: 5px;
}
.des-heading{
  text-align: center!important;
  margin-top: 1rem;
  font-size: 1.5rem;
}

.pricing-table .btn.round:after {
  background: #fff none repeat scroll 0 0;
  border-radius: 50px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  transition: all 0.4 linear;
  width: 100%;
  z-index: -1;
  -webkit-transform:scale(1);
  -moz-transform:scale(1);
  transform:scale(1);
}

.pricing-table:hover .btn.round:after {
  opacity: 0;
  transform: scale(0);
}

.fa-circle-check {
  color: #54d994;
}

.fa-circle-xmark {
  color: #e23c3c;
}

@media screen and (max-width:800px){
  .wrapper-contact{
    grid-template-columns: repeat(2,1fr);
  }
}

@media screen and (max-width:600px){
  .wrapper-contact{
    grid-template-columns: 1fr;
  }
}

.contact-header{

}







